<template>
  <Header title="About Ted" />

  <!-- Container -->
  <Container>
    <div class="grid gap-10 md:grid-cols-10">
      <div class="md:col-span-5 lg:col-span-6 my-auto">
        <h2 class="mb-0">About Ted Michaels</h2>
        <div class="w-20 h-1 my-5 bg-accent-light"></div>
        <p>
          Former radio news anchor and talk show host.
          <br />
          <br />
          He created, then hosted, a special program dealing with mental health,
          called #WellnessWednesdays.
          <br/>
          <br/>
          The show included experts explaining the various forms of mental health, as well as special guests, who talked about what they were diagnosed with, how they were treated and also offered advice to those who may be suffering in silence. The series won a National Champions of Mental Health Award, which was presented in Ottawa by the Governor General.

        </p>
      </div>
      <div class="md:col-span-5 lg:col-span-4">
        <img
          src="@/assets/images/smile.jpg"
          alt="Ted Michaels giving a presentation"
          class="w-full h-full object-cover rounded-xl"
        />
      </div>
    </div>
    <hr class="mt-20" />
    <div class="mt-20 grid gap-10 md:grid-cols-10">
      <div class="md:col-span-5 lg:col-span-5 order-1 md:order-none">
        <img
          src="@/assets/images/torch-2.jpg"
          alt="Ted Michaels running with olympic torch"
          class="w-full h-full object-cover rounded-xl"
        />
      </div>
      <div class="md:col-span-5 lg:col-span-5 my-auto">
        <p class="font-semibold text-gray-800 mb-4">
          Some of Ted’s other notable achievements in his community include:
        </p>
        <ul class="unstyled mt-2 space-y-4">
          <li
            v-for="(a, index) in achievements"
            :key="index"
            class="flex items-start"
          >
            <ArrowCircleRightIcon
              class="w-6 h-6 mr-3 text-accent mt-0.5 flex-shrink-0"
            />
            <div>
              <span v-html="a" class="text-gray-700"></span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <hr class="mt-20" />
    <div class="mt-20 grid gap-10 md:grid-cols-10">
      <div class="md:col-span-5 lg:col-span-6 my-auto">
        <h2 class="mb-0">
          Matt Dusk sings Sinatra in support of "Stand Up, Speak Out" a
          fundraising event for CMHA Halton Presented by Ted Michaels
        </h2>
        <div class="w-20 h-1 my-5 bg-accent-light"></div>
        <ul class="unstyled mt-2 space-y-4">
          <li class="flex items-start">
            <ArrowCircleRightIcon
              class="w-6 h-6 mr-3 text-accent mt-0.5 flex-shrink-0"
            />
            <div>
              <span class="text-gray-700"
                ><strong>Date & Time:</strong> Thursday September 21st, 2023 at
                7:30pm</span
              >
            </div>
          </li>
          <li class="flex items-start">
            <ArrowCircleRightIcon
              class="w-6 h-6 mr-3 text-accent mt-0.5 flex-shrink-0"
            />
            <div>
              <span class="text-gray-700"
                ><strong>Venue:</strong> Main Theatre</span
              >
            </div>
          </li>
          <li class="flex items-start">
            <ArrowCircleRightIcon
              class="w-6 h-6 mr-3 text-accent mt-0.5 flex-shrink-0"
            />
            <div>
              <span class="text-gray-700"
                ><strong>Show Length:</strong> Approx. 90 mins</span
              >
            </div>
          </li>
          <li class="flex items-start">
            <ArrowCircleRightIcon
              class="w-6 h-6 mr-3 text-accent mt-0.5 flex-shrink-0"
            />
            <div>
              <span class="text-gray-700"
                ><strong>Ticket Prices:</strong> Regular: $80 (All-in)</span
              >
            </div>
          </li>
        </ul>
        <a
          class="btn btn-xl mt-12"
          href="https://burlingtonpac.ca/events/stand-up-speak-out/?utm_source=burlingtontoday.com&amp;utm_campaign=burlingtontoday.com%3A%20outbound&amp;utm_medium=referral"
          target="_blank"
          >More Details</a
        >
      </div>
      <div class="md:col-span-5 lg:col-span-4">
        <img
          src="@/assets/images/matt-dusk.jpg"
          alt="Ted Michaels giving a presentation"
          class="w-full h-full object-cover rounded-xl"
        />
      </div>
    </div>
    <hr class="mt-20" />
  </Container>

  <!-- SimpleBrandCTA -->
  <SimpleBrandCTA />
</template>

<script>
import Header from "@/components/base/Header";
import Container from "@/components/layouts/Container.vue";
import SimpleBrandCTA from "@/components/layouts/cta/SimpleBrandCTA.vue";
import { ArrowCircleRightIcon } from "@heroicons/vue/outline";

const achievements = [
  "Selected as a celebrity torch bearer, when the 2015 Pan Am games torch came through Hamilton, on June 22.",
  "Initiated the <em>&ldquo;I'm In, Are You?&rdquo;​</em> marketing campaign for the 2011 Around the Bay Road Race, in support of St. Joseph's Healthcare Foundation. Helped in the promotion and awareness of the campaign, from the initial news conference, through an 8-week series, plus a daily blog.",
  "<strong>Surpassed the target goal of 200-thousand dollars with over 220-thousand dollars in donations and sponsorships obtained.</strong>",
  "In seven years, as captain of Team CHML, the team raised over 77--thousand dollars for St. Joseph's Healthcare Foundation.",
  "Invited then, Premier Kathleen Wynne, to run with Team CHML.",
  "Taught a starters 5K running class, building self-confidence and physical stamina.",
  "Initiated an interview with Rock and Roll Hall of Fame group, Chicago, when they appeared in Hamilton.  This was the only interview they did.  ",
];

export default {
  components: {
    Header,
    Container,
    SimpleBrandCTA,
    ArrowCircleRightIcon,
  },
  data() {
    return {
      achievements,
    };
  },
};
</script>
